import React from "react"
import classes from "./About.module.css"

const About = () => {
  return (
    <section className={`${classes.main} `}>
      <div className={classes.main_left}>
        <article className={`${classes.vcard} ${classes.floating}`}>
          <div>
            <h1>Hello</h1>
            <p>my name is</p>
          </div>
          <div className={classes.fn}>
            <p className={classes.title}>Front-end Developer</p>
            <p className={classes.given_name}>Yusuke</p>
          </div>
        </article>
      </div>
      <div className={classes.main_right}>
        Hi! I'm Yusuke, Full Stack Developer with three years experience
        designing and developing web applications. React.js is one of my
        favorite toys!
      </div>
    </section>
  )
}

export default About
