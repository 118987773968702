import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons"
import classes from "./Contact.module.css"

const Contact = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>CONTACT ME</div>
      <div className={classes.icons}>
        <a
          href="https://github.com/yusukeyoshino"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon className={classes.icon_github} icon={faGithub} />
        </a>
      </div>
      <a
        href="mailto:y.yoshino1011@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.mail}
      >
        y.yoshino1011@gmail.com
      </a>
    </div>
  )
}

export default Contact
