import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import classes from "../index.module.css"
import Navbar from "../components/navbar/navbar"
import Footer from "../components/Footer/Footer"
import About from "../components/About/About"
import styled from "styled-components"
import { Controller, Scene } from "react-scrollmagic"
import { Timeline, Tween } from "react-gsap"
import Skills from "../components/Skills/Skills"
import Works from "../components/Works/Works"
import styles from "../components/Works/Works.module.css"
import Contact from "../components/Contact/Contact"
import { Helmet } from "react-helmet"

export default function Home() {
  // const isBrowser = () => typeof window !== "undefined"

  // const [windowSize, setWindowSize] = useState(
  //   isBrowser ? window.document.body.clientWidth : 0
  // )

  const SectionWipes2Styled = styled.div`
    overflow: hidden;

    .pinContainer {
      height: 100vh;
      width: 100vw;
      overflow: hidden;
    }
    .pinContainer .panel {
      height: 100vh;ggg
      width: 100vw;
      position: absolute;
      text-align: center;
    }
  `
  // if (isBrowser) {
  //   window.addEventListener("resize", onResizeHandler)
  // }

  // const onResizeHandler = e => {
  //   setWindowSize(e.target.innerWidth)
  // }

  // if (windowSize > 1000) {
  //   return (
  //     <>
  //       <SectionWipes2Styled>
  //         <Controller>
  //           <Scene triggerHook="onLeave" duration="300%" pin>
  //             <Timeline wrapper={<div className="pinContainer" />}>
  //               <section className="panel">
  //                 <About />
  //               </section>
  //               <Tween from={{ x: "-100%" }} to={{ x: "0%" }}>
  //                 <section className="panel">
  //                   <Skills />
  //                 </section>
  //               </Tween>
  //             </Timeline>
  //           </Scene>
  //           <Works />

  //           <Contact />

  //           <Footer />
  //         </Controller>
  //       </SectionWipes2Styled>
  //     </>
  //   )
  // } else {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Yusuke Yoshino</title>
      </Helmet>
      <About />
      <Skills />
      <Works />
      <Contact />

      <Footer />
    </>
  )
  // }
}
